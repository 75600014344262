import React, { useState } from "react"
import { connect } from "react-redux"
import { graphql, useStaticQuery } from "gatsby"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import ResponsiveSpacing from "../components/responsive-spacing"
import Status from "../components/status"
import Layout from "../components/layout"
import Center from "../components/center"
import SEO from "../components/seo"
import Spacer from "../components/spacer"
import Img from "gatsby-image/withIEPolyfill"
import SVG from "../components/svg"
import Input from "../components/inputs"
import FormButton from "../components/form-button"
import BottomColorNav from "../components/bottom-color-nav"
import EMAIL_REGEXP from "../utils/email-regexp"
import HOST from "../utils/host"

const errorMsgs = {
  required: "Required",
  email: "E-Mail Address is Invalid",
}

const CategoriesStyled = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 10px 0;
  grid-gap: 15px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  .item {
    cursor: pointer;
    border-radius: 15px;
    border: 2px solid ${(p) => p.theme.DarkBlue};
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-flow: column;
    height: 115px;
  }

  .item:hover {
    background: ${(p) => p.theme.Blue};
    h3 {
      color: ${(p) => p.theme.LightBlue};
    }
  }

  .item:active {
    transform: translateY(2px);
  }

  .item.choosen {
    background: ${(p) => p.theme.DarkBlue};
    h3 {
      color: ${(p) => p.theme.LightBlue};
    }
  }

  h3 {
    margin: 0;
    padding: 0 7px;
    font-size: 1.4em;
    text-align: center;
    color: ${(p) => p.theme.Blue};
  }

  .img {
    width: 50px;
  }
`

const Categories = ({ fields, cat, setCat }) => {
  return (
    <CategoriesStyled>
      {fields.categories.map((c, i) => {
        return (
          <div
            key={i}
            tabIndex="0"
            role="radio"
            onClick={(e) => {
              setCat(c.name)
            }}
            className={`item ${cat === c.name ? "choosen" : ""}`}
          >
            <Img
              fluid={c.icon.localFile.childImageSharp.fluid}
              alt={c.name}
              className="img"
            />
            <h3
              tabIndex="0"
              dangerouslySetInnerHTML={{
                __html: c.name,
              }}
            />
          </div>
        )
      })}
    </CategoriesStyled>
  )
}

const ContactFormStyled = styled.form`
  padding: 40px 0;
`

const ContactForm = ({ dispatch, fields, cat, setCat, setCatError }) => {
  const [status, setStatus] = useState("")

  const [loading, setLoading] = useState(false)
  const { register, handleSubmit, errors, reset } = useForm()

  const required = (name) => {
    if (!errors[name]) return
    if (errors[name].message) return errors[name].message
    const type = errors[name].type
    if (errorMsgs[type]) return errorMsgs[type]
  }

  const onSubmit = (data) => {
    if (!cat) {
      setCatError(fields.categories_error_message)
      return
    }
    data.category = cat

    var opts = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }

    setLoading(true)
    fetch(HOST + "/contact-us", opts)
      .then((raw) => raw.json())
      .then((res) => {
        if (res.status === "SENT") {
          setStatus({
            code: 1,
            msg: fields.form_submitted_successfully_message,
          })
          reset()
          setLoading(false)
          setCat("")
        } else {
          setLoading(false)
          setStatus({ code: 0, msg: res.error })
        }
      })
      .catch((err) => {
        setLoading(false)
        setStatus({ code: 0, msg: err })
        console.log(err)
      })
  }

  return (
    <ContactFormStyled onSubmit={handleSubmit(onSubmit)}>
      <Input
        placeholder="Name"
        name="name"
        error={required("name")}
        register={register}
        registerValue={{
          required: "Required",
        }}
      />
      <Input
        type="email"
        placeholder="E-mail"
        name="email"
        error={required("email")}
        register={register}
        registerValue={{
          required: "Required ",
          pattern: {
            value: EMAIL_REGEXP,
            message: errorMsgs.email,
          },
        }}
      />
      <Input
        placeholder="Message"
        name="message"
        error={required("message")}
        register={register}
        registerValue={{
          required: "Required",
        }}
      />
      <Center>
        <Status status={status} setStatus={setStatus} />
        <Spacer height="20px" />
        <FormButton loading={loading} width="250px">
          {loading ? "PROCESSING" : fields.button_label}
        </FormButton>
      </Center>
    </ContactFormStyled>
  )
}

const ContactUsStyled = styled.div`
  max-width: 820px;
  margin: 123px auto 150px auto;

  @media (max-width: 768px) {
    margin-bottom: 50px;
  }

  h1 {
    text-align: center;
    color: ${(props) => props.theme.DarkBlue};
    margin: 0;
    text-transform: uppercase;
    font-size: 9.2em;
    font-weight: 800;
    letter-spacing: 2px;
    font-family: "interstate-compressed";
    @media (max-width: 768px) {
      font-size: 6.1em;
    }
  }

  h2 {
    font-weight: 800;
    letter-spacing: 2px;
    text-align: center;
    color: ${(props) => props.theme.Blue};
    margin: 0;
    text-transform: uppercase;
    font-size: 6em;
    font-family: "interstate-compressed";

    @media (max-width: 768px) {
      font-size: 4em;
    }
  }

  p {
    color: ${(props) => props.theme.DarkBlue};
    max-width: 580px;
    margin: 20px auto;
    text-align: center;
  }

  a {
    color: ${(props) => props.theme.DarkBlue};
  }

  .error {
    font-size: 1.4em;
    text-align: center;
    color: ${(p) => p.theme.Red};
  }
`

const ContactUs = ({ fields }) => {
  const [cat, setCat] = useState(null)
  const [catError, setCatError] = useState("")

  const { headline, statement, wave_color, button_label, sub_heading } = fields
  return (
    <ContactUsStyled>
      <h1
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: headline,
        }}
      />
      <Center margin="0 0 10px 0">
        <SVG name="SWave" color={wave_color} />
      </Center>
      <h2
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: sub_heading,
        }}
      />
      <p
        tabIndex="0"
        dangerouslySetInnerHTML={{
          __html: statement,
        }}
      />
      <Categories fields={fields} cat={cat} setCat={setCat} />
      <div className="error">{catError}</div>
      <ContactForm
        fields={fields}
        cat={cat}
        setCat={setCat}
        setCatError={setCatError}
      />
    </ContactUsStyled>
  )
}

const ConnectedContactUs = connect((state) => state)(ContactUs)

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: { slug: { eq: "contact-us-page" } }) {
        edges {
          node {
            acf {
              headline
              wave_color
              sub_heading
              statement
              search_title
              search_description
              share_headline
              share_comment
              form_submitted_successfully_message
              categories_error_message
              categories {
                icon {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 54) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                name
                sent_to
              }
              button_label
            }
          }
        }
      }
    }
  `)

  const fields = data.allWordpressPage.edges[0].node.acf
  const {
    search_title,
    share_headline,
    search_description,
    share_comment,
    share_image,
  } = fields
  return (
    <Layout>
      <SEO
        title={search_title}
        description={search_description}
        headline={share_headline}
        share_comment={share_comment}
        image={share_image}
      />
      <ResponsiveSpacing>
        <ConnectedContactUs fields={fields} />
      </ResponsiveSpacing>
      <BottomColorNav />
    </Layout>
  )
}

export default ContactUsPage

import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import SVG from "../components/svg"

const CTAStyled = styled.div`
  position: relative;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 45px;
  cursor: pointer;
  font-family: "interstate-compressed";

  svg.svg {
    color: ${p => p.theme.DarkBlue};
    height: 21px;
    margin-left: 8px;
    position: relative;
  }

  @media (min-width: 768px) {
    &:hover span {
      text-decoration: underline;
    }

    &:hover svg {
      animation-name: moving-arrow;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }
`

const CTA = ({ children, color }) => {
  const theme = useContext(ThemeContext)
  return (
    <CTAStyled>
      <span>{children}</span>
      <SVG name="Arrow" className="svg" color={color || theme.DarkBlue} />
    </CTAStyled>
  )
}

export default CTA

import React from "react"
import styled from "styled-components"

const CenterStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  max-width: ${p => p.width || "100%"};
  margin: ${p => p.margin || "0 auto"};

  @media (max-width: 768px) {
    margin: ${p => p.mobileMargin || "20px auto"};
  }
`

const Center = props => {
  const { children, ...rest } = props
  return <CenterStyled {...rest}>{children}</CenterStyled>
}

export default Center

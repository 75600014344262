import React from "react"
import styled from "styled-components"

const SpacerStyled = styled.div `
  position: relative;
  display: flex;
  user-select: none;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  z-index: -1;
  height: ${p => p.height};
  @media (max-width: 768px) {
    height: ${p => p.mobileHeight || p.height};
  }
`

const Spacer = props => {
  const {
    children,
    ...rest
  } = props
  return <SpacerStyled {...rest}>{children}</SpacerStyled>
}

export default Spacer
